import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main className="text-center mt-20">
      <h1 className="text-3xl font-bold mb-4">Puslapis nerastas</h1>
      <p>
        Deja, šio puslapio neradome. <Link className="underline" to="/">Grįžti namo</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage

export const Head = () => <title>Puslapis nerastas</title>
